import React, { MouseEvent } from "react";
import styled from "styled-components";
import { ModalProps } from "./types";
import { Flex, MotionBox } from "../Layout";
import Button from "../Button/MainButton";
import { IconButton } from "../Button/IconButton";

export const mobileFooterHeight = 73;

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  // border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  display: flex;
  padding: 12px 24px;
  background: ${({ background }) => background || "transparent"};
  justify-content: space-between;
  @media (max-width: 1200px) {
  }
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(90vh - ${mobileFooterHeight}px);
  @media (max-width: 1200px) {
    display: flex;
    max-height: 90vh;
  }
`;

export const ModalCloseButton: React.FC<
  React.PropsWithChildren<{
    onDismiss: ModalProps["onDismiss"];
    color?: string;
  }>
> = ({ onDismiss, color }) => {
  return (
    <IconButton
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDismiss?.();
      }}
    >
      <img src={"/images/icons/close.svg"} width="20px" height="20px" />
    </IconButton>
  );
};

export const ModalBackButton: React.FC<
  React.PropsWithChildren<{ onBack: ModalProps["onBack"] }>
> = ({ onBack }) => {
  return (
    <IconButton onClick={onBack} area-label="go back">
      <img src={"images/icons/arrow.svg"} width="20px" height="20px" />
    </IconButton>
  );
};

export const ModalContainer = styled(MotionBox)`
  overflow: hidden;
  background: ${({ theme }) => theme.modal.background};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow};
  border: 3px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: ${({ theme }) => theme.radii.card};
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: 20;
  position: absolute;
  bottom: 0;
  max-width: none !important;
  // min-height: 300px;

  @media (min-width: 1200px) {
    width: auto;
    position: auto;
    bottom: auto;
    max-height: 100vh;
  }
`;
