import styled from "styled-components";
import TitlePageView from "../view/homepage/title-view";
import TradeOverview from "../view/homepage/trade-overview";
import BuildOn from "../view/homepage/build-on";
import JoinCommunity from "../view/homepage/join-community";
import TradeOnLiq from "../view/homepage/trade-on-liq";
import Footer from "../view/footer";

const HomeWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
Homepage.propTypes = {};

function Homepage() {
  return (
    <HomeWrapper>
      <TitlePageView />
      {/* <TradeOverview />
      <TradeOnLiq />
      <BuildOn />
      <JoinCommunity />
      <Footer /> */}
    </HomeWrapper>
  );
}

export default Homepage;
