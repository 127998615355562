import { ThemeProvider } from "styled-components";
import ModalProvider from "./components/Modal/ModalContext";

const selectedTheme = {
  name: "Light",
  colors: {
    background: "#000000",
    cardBorder: "#FFF",
    text: "#FFF",
    subText: "#8D8D8D",
    disable: "#484848",
    button: {
      text: "#1F2024",
      background: "#000000",
    },
    link: {
      text: "teal",
      opacity: 1,
    },
  },
  modal: {
    text: "#1F2024",
    background: "#DDF9FD",
    overlay: "rgba(31, 32, 36, 0.20)",
  },
  shadows: {
    warning: "",
    success: "",
    focus: "",
    boxShadow:
      "0px 11px 15px 0px rgba(5, 5, 5, 0.20), 0px 24px 38px 0px rgba(5, 5, 5, 0.14), 0px 4px 4px 0px rgba(5, 5, 5, 0.25)",
  },
  radii: {
    card: "28px",
  },
};

const Providers: React.FC<
  React.PropsWithChildren<{
    children: React.ReactNode;
  }>
> = ({ children }) => {
  return (
    <ThemeProvider theme={selectedTheme}>
      <ModalProvider>{children}</ModalProvider>
    </ThemeProvider>
  );
};

export default Providers;
