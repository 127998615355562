import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import Providers from "./Providers";
import Homepage from "./pages/homepage";
import NotFoundPage from "./pages/not-found";
import Footer from "./view/footer";

const AppWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const App: React.FC = () => {
  let location = useLocation();

  const hideTopNav = location.pathname === "";
  const hideSocialFooter = location.pathname === "/";
  const hideFooter = location.pathname === "/";

  return (
    <Providers>
      <AppWrapper>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        {!hideFooter && <Footer />}
      </AppWrapper>
    </Providers>
  );
};

export default App;
